import * as React from 'react'
import Desktop from './desktop'
import Mobile from './mobile'

export default function Nav() {
	return (
		<>
            <Mobile />
            <Desktop />
		</>
	)
}