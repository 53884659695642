import { OverviewContext } from 'components/context/OverviewContext';
import React, {useContext,useState,useEffect,useRef} from "react";
import * as Styled from "./Chartcard.style"
import {
    ResponsiveContainer,
    AreaChart,
    XAxis,
    YAxis,
    Area,
    Tooltip,
    CartesianGrid,
  } from "recharts";

function Chartcard() {


    return (
    <>
        <Styled.Chartcover>

        </Styled.Chartcover>
    </>
    );
}


export default Chartcard;
  